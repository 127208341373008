import CurrentIssue from "../../Component/Third-PartyAds/CurrentIssue";
import { useStaticPageData } from "../../Middleware/useStaticPageData";
import Loader from "../../Component/UI/Loader";
import useFetchMetaTags from "../../Middleware/useFetchMetaTags";
// import { Helmet } from "react-helmet-async";

const ContactUs = () => {
  const { data: contact, isLoading, error } = useStaticPageData("contact-us");
  // const { data: tags } = useFetchMetaTags("contact-us");

  return (
    <>
      {" "}
      {/* <Helmet>
        <title>{`${tags?.data?.title}`}</title>
        <meta name="description" content={`${tags?.data?.description}`} />
        <link rel="canonical" href={`${tags?.data?.canonical}`} />
        <meta property="og:locale" content={`${tags?.data?.og_locale}`} />
        <meta property="og:type" content={`${tags?.data?.og_type}`} />
        <meta property="og:title" content={`${tags?.data?.og_title}`} />
        <meta
          property="og:image"
          content={`https://admin.asiantrader.biz/wp-content/uploads/sites/1/2024/09/AT-logo-White-3-1.png`}
        />
        <meta
          property="og:description"
          content={`${tags?.data?.og_description}`}
        />
        <meta property="og:url" content={`${tags?.data?.og_url}`} />
        <meta property="og:site_name" content={`${tags?.data?.og_site_name}`} />
        <meta
          property="og:updated_time"
          content={`${tags?.data?.og_updated_time}`}
        />
        <meta
          property="article:published_time"
          content={`${tags?.data?.article_published_time}`}
        />
        <meta
          property="article:modified_time"
          content={`${tags?.data?.article_modified_time}`}
        />
        <meta name="twitter:card" content={`${tags?.data?.twitter_card}`} />
        <meta name="twitter:title" content={`${tags?.data?.twitter_title}`} />
        <meta
          name="twitter:description"
          content={`${tags?.data?.twitter_description}`}
        />
      </Helmet> */}
      <section className="mb-8 bg-blur">
        <div className="container mx-auto px-7">
          {isLoading ? (
            <Loader />
          ) : error ? (
            <div></div>
          ) : (
            <>
              <div className="px-3 border-b border-greyb pb-2 mb-5">
                <div>
                  <h1 className="text-dblack text-2xl font-bold">CONTACT US</h1>
                </div>
              </div>

              <div className="grid grid-cols-12 gap-5">
                <div className="lg:col-span-9 md:col-span-8 col-span-12">
                  <div className="flex flex-col  sm:flex-row mb-[51px]">
                    {contact?.data?.managers_lists?.map(
                      (manager, index, arr) => (
                        <div key={index}>
                          {index % 2 === 0 ? (
                            <div
                              className={`${
                                index === 0
                                  ? "lg:mx-3 ml-3"
                                  : index === 2
                                  ? "sm:ml-5 ml-2 mt-4 sm:mt-0"
                                  : ""
                              }`}
                            >
                              {/* {console.log(arr[index + 1])} */}
                              <div className="sm:mb-[43px] mb-[10px] leading-7 text-base">
                                <h4 className="font-semibold">
                                  {manager.designation}
                                </h4>
                                <p className="wow text-sm fadeInDown">
                                  {manager.manager_name}
                                </p>
                                <p className="wow text-sm  fadeInDown">
                                  Tel: {manager.manager_phone_number}
                                </p>
                                <p className="wow text-sm fadeInDown">
                                  Email: {manager.manager_email_address}
                                </p>
                              </div>
                              <div className="leading-7 text-base">
                                <h4 className="font-semibold">
                                  {arr[index + 1]?.designation}
                                </h4>
                                <p className="wow text-sm fadeInDown">
                                  {arr[index + 1]?.manager_name}
                                </p>
                                <p className="wow text-sm fadeInDown">
                                  Tel: {arr[index + 1]?.manager_phone_number}
                                </p>
                                <p className="wow text-sm fadeInDown">
                                  Email: {arr[index + 1]?.manager_email_address}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      )
                    )}
                  </div>
                  <div>
                    <iframe
                      src={contact?.data?.contact_us_map}
                      className="w-[100%] h-[420px] iwidth"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
                <div className="lg:col-span-3 md:col-span-4 col-span-12">
                  <div className="bg-[#231E1E] p-5 rounded-[10px]">
                    <CurrentIssue />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default ContactUs;

//  {
//    managersLists.map((manager, index) => (
//      <div key={index} className="w-full sm:w-1/2 px-3 mb-6">
//        <div className="leading-7 text-base">
//          <h4 className="font-semibold">{manager.designation}</h4>
//          <p className="wow fadeInDown">{manager.manager_name}</p>
//          <p className="wow fadeInDown">Tel: {manager.manager_phone_number}</p>
//          <p className="wow fadeInDown">
//            Email: {manager.manager_email_address}
//          </p>
//        </div>
//      </div>
//    ));
//  }
